//
//
//
import "../../assets/javascript/polyfills"
import "../../assets/javascript/wizard"

document.addEventListener("turbolinks:load", function() {
  document
    .querySelectorAll('select[choice]')
    .forEach(function(elem){
      new Choices(elem)
    })

  var school_id = document.querySelector('#user_school_id')
  var school_name = document.querySelector('#user_school_name')

  if(school_name && school_id) {
    school_id.addEventListener('change', function(ev) {
      var school_name_container = document.querySelector('.user_school_name')
      var hint = document.querySelector('.user_school_id .form-text')
      var value = ev.target.value

      if(ev.target.value) {
        school_name_container.classList.add('d-none')
        hint.classList.add('d-none')
      } else {
        school_name_container.classList.remove('d-none')
        hint.classList.remove('d-none')
      }
    })
  }

  document
    .querySelectorAll('.teacher_view_codes')
    .forEach(elem => {
      elem.addEventListener("click", (ev) => {
        ev.preventDefault()

        var isHidden = document.querySelector('.pin-was-hidden').classList.contains('d-none')

        if(isHidden) {
          document
            .querySelectorAll('.pin-was-hidden')
            .forEach(elem => {
              elem.classList.remove('d-none')
            })

          document
            .querySelectorAll('.pin-was-shown')
            .forEach(elem => {
              elem.classList.add('d-none')
            })
        } else {
          document
            .querySelectorAll('.pin-was-hidden')
            .forEach(elem => {
              elem.classList.add('d-none')
            })

          document
            .querySelectorAll('.pin-was-shown')
            .forEach(elem => {
              elem.classList.remove('d-none')
            })
        }

        return false;
      })
    })

  document
    .querySelectorAll('.disabled-user-delete')
    .forEach(function(elem){
      elem.addEventListener("click", (ev) => {
        ev.preventDefault()

        alert('Negalima ištrinti viktoriną jau sprendusių mokinių')

        return false;
      })
    })

  document
    .querySelectorAll('.seminar-description-selector')
    .forEach(function(elem){
      document
        .querySelector('#seminar_attendant_seminar_id')
        .addEventListener('change', function(ev) {
          showSeminarDescriptionItem(ev.target.value)
        })

    })

  function showSeminarDescriptionItem(id) {
    document
      .querySelectorAll('.seminar-description-item')
      .forEach(function(elem){
        elem.classList.add('d-none')
      })

    document
      .querySelector(".seminar-description-item[seminar='"+id+"']")
      .classList
      .remove('d-none')
  }

  document
    .querySelectorAll('.rodyti_atsakymus')
    .forEach(function(elem){
      elem.addEventListener("click", (ev) => {
        ev.preventDefault()

        elem.classList.add('d-none')

        document.querySelector('#atsakymai').classList.remove('d-none')

        return false;
      })
    })

  document
    .querySelectorAll('.btn-upload-mask')
    .forEach(function(elem){
      elem.onchange = function() {
        elem.closest('form').submit()
      }
    })
})
