// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import Bugsnag from '@bugsnag/js'
//import "channels"
import "bootstrap"
//import "../assets/stylesheets/application"
//import "../assets/stylesheets/main"
//import 'bootstrap/dist/css/bootstrap.min.css';
//import 'bootstrap/scss/bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'choices.js/public/assets/scripts/choices.js'
import 'choices.js/public/assets/styles/choices.css'
import "../../assets/stylesheets/main"
import "../../assets/javascript/main"
//import "../../assets/javascript/wizard"

//const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

Rails.start()
Turbolinks.start()
ActiveStorage.start()

if(process.env.NODE_ENV != 'development'){
  Bugsnag.start("a29dc756be85da2b5dafd77a86ad6c31")
}

require("trix")
require("@rails/actiontext")
window.Choices = require('choices.js')
//require("wizard")
